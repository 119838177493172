import React from 'react';
import styles from './ServicesGallery.styles';
import { css } from 'aphrodite';
import SectionTitle from '../../../components/ui/SectionTitle/SectionTitle';
import Utils from '../../../library/utils';

const ServicesGallery = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <SectionTitle
        title={'JJD Van Team in Action'}
        subTitle={props.gallerySubtitle}
      />
      <section className={css(styles.photos)}>
        {(Utils.shuffle(props.photos)).map((photo, index) => (
          <div className={css(styles.imgContainer)} key={index}>
            <img className={css(styles.img)} src={photo} alt={'Man and van London'}/>
          </div>
        ))}
      </section>
    </div>
  </div>
);

export default ServicesGallery;
