import React, {Component} from 'react';
import WeDoItAll from './WeDoItAll';

class WeDoItAllContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredIndex: null
    }
  }

  hoverService = (index) => {
    this.setState({
      hoveredIndex: index
    });
  };

  leaveHoverService = () => {
    this.setState({
      hoveredIndex: null
    });
  };

  render() {
    return (
      <WeDoItAll
        {...this.props}
        hoverService={this.hoverService}
        leaveHoverService={this.leaveHoverService}
        hoveredIndex={this.state.hoveredIndex}
      />
    )
  }
}

export default WeDoItAllContainer



