import React from "react";
import styles from "./HomepageHero.styles";
import { css } from "aphrodite";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import COLORS from "../../../styles/colors";
import { SERVICES } from "../../../library/services";
import STYLES from "../../../styles/shared";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button/Button";
const HomepageHero = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.aasa)} />
    <div className={css(styles.content)}>
      <div style={{ height: 10 }} className={css(STYLES.desktopOnly)} />

      <div className={css(styles.row)}>
        <div className={css(styles.categoryDropdownArea, STYLES.noSelect)}>
          <h1 className={css(styles.h1)}>Low cost man with a van</h1>
          <p
            style={{
              margin: 0,
              color: COLORS.jjdGreyish,
              fontWeight: "normal",
              paddingBottom: 34,
            }}
            className={css(STYLES.desktopOnly, styles.guaranteed)}
          >
            Guaranteed lowest prices - get a free quote today
          </p>
          <div className={css(styles.mobileDescription)}>
            <img
              src={require("../../../assets/images/trustpilotstars.png")}
              className={css(styles.trustPilotStars)}
            />
            <p className={css(styles.mobileDescriptionText)}>
              {" "}
              Join over 1000+ Happy customers
            </p>
          </div>
          <div
            className={css(styles.categoryDropdown)}
            onClick={props.toggleServicesDropdown}
          >
            <div className={css(styles.iconContainer)}>
              {SERVICES[props.selectedServiceIndex].icon({
                color: COLORS.white,
                fontSize: 32,
              })}
            </div>
            <h3 className={css(styles.selectedCategory)}>
              {SERVICES[props.selectedServiceIndex].name}
            </h3>
            <div style={{ width: 60, paddingRight: 24 }}>
              {!props.servicesDropdownOpen && (
                <ArrowDropDown style={{ color: COLORS.white, fontSize: 32 }} />
              )}
              {props.servicesDropdownOpen && (
                <ArrowDropUp style={{ color: COLORS.white, fontSize: 32 }} />
              )}
            </div>
          </div>

          {props.servicesDropdownOpen && (
            <div className={css(styles.categoriesContainer, STYLES.noSelect)}>
              {SERVICES.map((service, index, array) => (
                <div
                  onClick={() => props.selectService(index)}
                  key={index}
                  className={css(
                    styles.option,
                    index === array.length - 1 && styles.lastOption,
                    index === 0 && styles.firstOption
                  )}
                >
                  <div className={css(styles.iconContainer)}>
                    {service.icon({ color: COLORS.offBlack, fontSize: 32 })}
                    {/*<Weekend style={{color: COLORS.offBlack, fontSize: 32}}/>*/}
                  </div>
                  <h3 className={css(styles.optionName)}>{service.name}</h3>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={css(styles.realVanImgContainer)}>
          <img
            alt={"JJD man and van"}
            className={css(styles.realVanImg)}
            draggable={false}
            src={require("../../../assets/hero/header-img.png")}
          />
        </div>
      </div>

      {/* <div style={{height: 24}}/> */}

      <div className={css(styles.inputsContainer)}>
        <div className={css(styles.singleInputContainer)}>
          <input
            className={css(
              styles.input,
              styles.pickupInput,
              props.showPickupSuggestions &&
                props.bookingAddresses.suggestions.pickup.length >= 1 &&
                styles.pickupInputSuggestionsOpen
            )}
            placeholder={"Pickup address"}
            onChange={props.handlePickupChange}
            value={props.pickup}
          />
          {props.showPickupSuggestions &&
            props.bookingAddresses.suggestions.pickup.length >= 1 && (
              <div className={css(styles.suggestionsContainer)}>
                {props.bookingAddresses.suggestions.pickup.map((hit) => (
                  <MenuItem
                    key={hit.udprn}
                    onClick={() => props.selectPickupAddress(hit)}
                  >
                    {hit.suggestion}
                  </MenuItem>
                ))}
              </div>
            )}
        </div>

        <div className={css(styles.singleInputContainer)}>
          <input
            className={css(styles.input, styles.dropoffInput)}
            placeholder={"Destination"}
            onChange={props.handleDropoffChange}
            value={props.dropoff}
          />
          {props.showDropoffSuggestions &&
            props.bookingAddresses.suggestions.dropoff.length >= 1 && (
              <div className={css(styles.suggestionsContainer)}>
                {props.bookingAddresses.suggestions.dropoff.map((hit) => (
                  <MenuItem
                    key={hit.udprn}
                    onClick={() => props.selectDropoffAddress(hit)}
                  >
                    {hit.suggestion}
                  </MenuItem>
                ))}
              </div>
            )}
        </div>

        <div className={css(styles.goButtonContainer)}>
          <Button
            style={{ height: 48 }}
            disabled={props.loading}
            variant="contained"
            className={css(styles.checkPricesButton)}
            onClick={props.checkPrices}
          >
            {props.loading && (
              <CircularProgress color={"inherit"} size={16} thickness={6} />
            )}
            {!props.loading && "Check prices"}
          </Button>

          {/*<PrimaryButton title={'Check prices'} style={{width: 180}} loading={props.loading} large={true} flat={true} color={'pink'} onClick={props.checkPrices}/>*/}
        </div>
      </div>

      {/* <p className={css(styles.note)}>* We can cover jobs at anytime - even 1 hour from now</p> */}
      {/*<p className={css(styles.note)}>* We have thousands of drivers ready to cover jobs at anytime - even 1 hour from now</p>*/}

      <div className={css(styles.heightAfterHeroSec)} />
    </div>
    <div className={css(styles.contactContainerMobile)}>
      <p className={css(styles.contactText)}>Or prefer to call for a quote ?</p>
      <p className={css(styles.contactNum)}>
        <a className={css(styles.contactNum)} href="tel:02037452802">
          020 3745 2802
        </a>
      </p>
    </div>
    <div
      className={css(styles.realVanImgContainerMobile)}
      style={{ maxWidth: "100%" }}
    >
      <img
        alt={"JJD man and van"}
        className={css(styles.realVanImg)}
        draggable={false}
        src={require("../../../assets/hero/header-img.png")}
      />
    </div>
  </div>
);

export default HomepageHero;
