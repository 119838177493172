import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 32,
    paddingTop: 64
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  }
});

export default styles;
