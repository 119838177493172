import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    background: COLORS.white,
    minHeight: '80vh',
    padding: 16,
    paddingBottom: 224,
    paddingTop: 64,
    [QUERIES.mobile]: {
      paddingBottom: 64,
      paddingTop: 0,
    }
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  title: {
    color: COLORS.offBlack,
    fontSize: 48,
    textAlign: 'center',
    paddingBottom: 6,
    position: 'relative'
  },
  subTitle: {
    textAlign: 'center',
    marginTop: 0
  },
  titleBottomBorder: {
    height: 4,
    width: 100,
    bottom: 200,
    left: 100,
    background: COLORS.kgGreen,
    margin: '0 auto',
    marginBottom: 64,
    borderRadius: 2
  },
  servicesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  serviceBox: {
    padding: 24,
    flex: '0 0 33.33%',
    [QUERIES.mobile]: {
      flex: '0 0 50%',
      padding: 16
    },
    [QUERIES.lessThan470]: {
      flex: '0 0 100%',
      padding: 16
    },

  },
  serviceName: {
    position: 'relative',
    zIndex: 2,
    fontSize: 24,
    margin: '150px 0 0 0',
    color: COLORS.white,
    [QUERIES.mobile]: {
      marginTop: 120,
    }
  },
  serviceImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 0,
    opacity: 0.5,
    transition: '150ms',
  },
  serviceImgHovered: {
    opacity: 0.5
  },
  serviceBoxContent: {
    borderRadius: 16,
    padding: 32,
    height: 264,
    // minWidth: 336,
    display: 'flex',
    position: 'relative',
    boxShadow: '0 16px 40px rgba(0,0,0,0.16)',
    overflow: 'hidden',
    transition: '250ms',
    ':hover': {
      cursor: 'pointer',
      transform: 'translateY(-8px)'
    }
  }
});

export default styles;
