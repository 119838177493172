import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';

/**
 * Seamless Responsive Photo Grid from CSS-Tricks
 * @author https://css-tricks.com/seamless-responsive-photo-grid/
 */

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 16,
    paddingTop: 112,
    paddingBottom: 64
  },
  content: {
    maxWidth: 1400,
    margin: '0 auto'
  },
  photos: {
    /* Prevent vertical gaps */
    lineHeight: 0,
    columnCount: 4,
    columnGap:   0,
    [QUERIES.lessThan1000]: {
      columnCount: 3
    },
    [QUERIES.lessThan800]: {
      columnCount: 2
    },
    [QUERIES.lessThan600]: {
      columnCount: 2
    }
  },
  imgContainer: {
    width: '100%',
    padding: 8
  },
  img: {
    width: '100%',
    height: 'auto',
    // margin: 8
  }
});

export default styles;
