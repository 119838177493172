import COLORS from "../../../styles/colors";
import { StyleSheet } from "aphrodite";
import QUERIES from "../../../styles/queries";
import { FONTS } from "../../../styles/fonts";
import { colors } from "@material-ui/core";

const inputRadiusSize = " 12px ";
const pickupInputSize = " 48px ";
const categoryDropdownSize = " 30px ";

const styles = StyleSheet.create({
  root: {
    background: "linear-gradient(to bottom, #ffffff 1%, #f7f7f7)",
    padding: "32 0",
  },
  content: {
    margin: "0 auto",
    maxWidth: "100%",
    height: "auto",
    flexDirection: "row",
    position: "relative",
    [QUERIES.lessThan800]: {
      maxWidth: 700,
    },
    [QUERIES.lessThan600]: {
      maxWidth: 320,
    },
    [QUERIES.lessThan400]: {
      maxWidth: 290,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  realVanImgContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [QUERIES.mobile]: {
      marginTop: 50,
      display: "none",
    },
  },
  contactContainerMobile: {
    margin: "20px 0 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [QUERIES.desktop]: {
      display: "none",
    },
  },
  heightAfterHeroSec: {
    [QUERIES.desktop]: {
      height: 50,
    },
  },
  contactText: {
    fontSize: 16,
    margin: "0px",
    color: COLORS.jjdGreyish,
    fontWeight: 500,
  },
  contactNum: {
    fontSize: 24,
    margin: "0",
    color: COLORS.jjdGreyish,
    fontWeight: 700,
    textDecoration: "none",
  },
  realVanImgContainerMobile: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [QUERIES.desktop]: {
      marginTop: 50,
      display: "none",
    },
  },
  realVanImg: {
    maxHeight: 248,
    maxWidth: "100%",
    minWidth: 300,
  },
  categoryDropdownArea: {
    position: "relative",
    flex: 1,
    maxWidth: 400,
    // overflow:'hidden',
    [QUERIES.desktop]: {
      paddingTop: 14,
      minWidth: 200,
      marginLeft: 118,
    },
    [QUERIES.mobile]: {
      margin: "0 auto",
      // flex: '0 0 100%',
      // ":before": {
      //   content: "''",
      //   background: '#d8d8d8',
      //   width: 425,
      //   display: 'block',
      //   position: 'absolute',
      //   top:"-55%",
      //   left: "20%",
      //   height: 330,
      //   opacity: 0.2,
      //   borderRadius: "45%",
      // }
    },
  },
  categoryDropdown: {
    width: "100%",
    height: 100,
    borderRadius: 20,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    background: COLORS.black,
    ":hover": {
      background: COLORS.black,
      cursor: "pointer",
    },
    [QUERIES.desktop]: {
      borderRadius: categoryDropdownSize + categoryDropdownSize + "0 0",
      paddingBottom: 20,
    },
    [QUERIES.mobile]: {
      height: 66,
    },
  },
  option: {
    width: "100%",
    borderBottom: "1px solid whitesmoke",
    height: 80,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    ":hover": {
      background: "rgba(0,0,0,0.06)",
      cursor: "pointer",
    },
  },
  firstOption: {
    borderRadius: inputRadiusSize + inputRadiusSize + "0 0",
  },
  lastOption: {
    border: "none",
    borderRadius: "0 0" + inputRadiusSize + inputRadiusSize,
  },
  categoriesContainer: {
    position: "absolute",
    left: 0,
    zIndex: 10,
    marginTop: 0,
    borderRadius: inputRadiusSize,
    right: 0,
    // height: 480,
    width: "100%",
    background: COLORS.white,
    boxShadow: "0 16px 40px rgba(0,0,0,0.24)",
    [QUERIES.desktop]: {
      marginTop: -30,
    },
  },
  selectedCategory: {
    color: COLORS.white,
    fontSize: 20,
    flex: 1,
    [QUERIES.mobile]: {
      fontSize: 16,
    },
  },
  optionName: {
    color: COLORS.offBlack,
    fontSize: 20,
    flex: 1,
    [QUERIES.mobile]: {
      fontSize: 16,
    },
  },
  iconContainer: {
    width: 80,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  h1: {
    color: COLORS.jjdGreyish,
    fontWeight: 600,
    lineHeight: "normal",
    [QUERIES.desktop]: {
      fontSize: 59,
      marginBottom: 12,
    },
    [QUERIES.mobile]: {
      marginBottom: 16,
      fontSize: 50,
    },
    [QUERIES.lessThan600]: {
      fontSize: 21,
      fontWeight: 600,
      textAlign: "center",
    },
  },
  smallerH1: {
    fontSize: 28,
    [QUERIES.mobile]: {
      display: "none",
    },
  },
  p: {
    color: COLORS.kgDarkGreen,
    marginTop: 12,
    // fontWeight: '700'
  },
  subP: {
    color: COLORS.kgDarkGreen,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 8,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [QUERIES.desktop]: {
      marginTop: -30,
      marginLeft: 118,
      marginRight: 118,
      boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
      borderRadius: "48px",
    },
    [QUERIES.mobile]: {
      marginTop: 30,
    },
    [QUERIES.lessThan50]: {
      marginTop: 4,
    },
  },
  singleInputContainer: {
    flex: 1,
    position: "relative",
    [QUERIES.mobile]: {
      flex: "0 0 100%",
      marginTop: 20,
      borderRadius: "48px",
    },
    [QUERIES.lessThan800]: {
      height: 55,
      margin: "13px auto",
    },
  },
  input: {
    padding: 32,
    border: "none",
    color: COLORS.offBlack,
    opacity: "rgba(0,0,0,0.4)",
    fontWeight: "normal",
    width: "100%",
    height: "100%",
    [QUERIES.mobile]: {
      fontSize: 16,
    },
    [QUERIES.desktop]: {
      paddingRight: 0,
    },
  },
  pickupInput: {
    [QUERIES.desktop]: {
      borderRight: "1px solid " + COLORS.offWhite,
      borderRadius: pickupInputSize + "0 0" + pickupInputSize,
    },
    [QUERIES.mobile]: {
      borderBottom: "1px solid " + COLORS.offWhite,
      borderRadius: 48,
      boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    },
    [QUERIES.lessThan800]: {
      boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    },
    overflow: "hidden",
  },
  pickupInputSuggestionsOpen: {
    [QUERIES.desktop]: {
      borderRadius: inputRadiusSize + "0 0 0",
    },
  },
  dropoffInput: {
    [QUERIES.mobile]: {
      borderRadius: 48,
    },
    [QUERIES.lessThan800]: {
      boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
      marginTop: 16,
    },
  },
  note: {
    fontSize: 16,
    color: COLORS.white,
    marginLeft: 24,
    marginTop: 24,
    fontWeight: "700",
    marginBottom: 0,
  },
  suggestionsContainer: {
    boxShadow: "0 16px 40px rgba(0,0,0,0.12)",
    maxHeight: "60vh",
    overflowY: "scroll",
    position: "absolute",
    top: 90,
    left: 0,
    right: 0,
    zIndex: 11,
    background: COLORS.white,
  },
  goButtonContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    [QUERIES.desktop]: {
      background: COLORS.white,
      borderRadius: "0" + pickupInputSize + pickupInputSize + "0",
    },
    [QUERIES.mobile]: {
      flex: "0 0 100%",
      // background: COLORS.hotPink,
    },
    [QUERIES.lessThan800]: {
      marginTop: 18,
    },
  },
  checkPricesButton: {
    // boxShadow: 'none',
    fontWeight: "normal",
    fontFamily: FONTS.Buttons,
    textTransform: "none",
    minWidth: 100,
    fontSize: 20,
    height: 60,
    borderRadius: 60,
    paddingLeft: 42,
    paddingRight: 42,
    boxShadow: "none",
    backgroundColor: COLORS.jjdRed,
    color: COLORS.white,
    [QUERIES.mobile]: {
      borderRadius: 48,
      marginTop: 35,
    },
    [QUERIES.lessThan800]: {
      height: 55,
    },
  },
  guaranteed: {
    display: "block",
    [QUERIES.desktop]: {
      paddingLeft: 20,
      fontSize: 20,
      display: "block",
      position: "relative",
      ":before": {
        content: "' '",
        width: 4,
        height: 54,
        position: "absolute",
        left: 0,
        display: "block",
        background: COLORS.jjdRed,
      },
    },
    [QUERIES.lessThan600]: {
      fontSize: 13,
      fontWeight: 500,
      paddingLeft: 0,
      paddingBottom: 10,
      ":before": {
        content: "' ' ",
        width: 5,
        height: 15,
        position: "absolute",
        left: "-8px",
        display: "block",
        background: COLORS.jjdRed,
      },
    },
  },
  mobileDescription: {
    [QUERIES.desktop]: {
      display: "none",
    },
    [QUERIES.mobile]: {
      display: "none",
    },
    [QUERIES.lessThan600]: {
      margin: "1px 0 20px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "stretch",
    },
  },
  trustPilotStars: {
    width: "100%",
    maxWidth: 80,
  },
  mobileDescriptionText: {
    fontSize: 13,
    marginLeft: 8,
  },
});

export default styles;
