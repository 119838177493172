import React from 'react'
import Layout from '../containers/gatsby/layout'
import SEO from '../containers/gatsby/seo'
import FeatureList from '../containers/sections/FeatureList/FeatureList'
import CredibilitySection from '../containers/sections/Credibility/Credibility'
import HelpLifting from '../containers/sections/HelpLifting/HelpLifting'
import HowItWorksContainer from '../containers/sections/HowItWorks/HowItWorksContainer'
import StartBookingFlowContainer from '../containers/heroes/HomepageHero/HomepageHeroContainer'
import WeDoItAllContainer from '../containers/sections/WeDoItAll/WeDoItAllContainer';
import ServicesGallery from '../containers/services/ServicesGallery/ServicesGallery';
import GeneralBlurb from '../containers/sections/GeneralBlurb/GeneralBlurb';

const HomePage = (props) => (
  <Layout location={props.location} greenHeader={true}>
    <SEO title="JJD - Man and van" keywords={[`JJD`, `man and van`, `man and van london`, `man and van app`]} />
    <StartBookingFlowContainer />
    <FeatureList />
    <CredibilitySection />
    <ServicesGallery
      gallerySubtitle={'Get moving with JJD in minutes'}
      photos={[
        require('../assets/service-gallery/1.jpg'),
        require('../assets/service-gallery/2.png'),
        require('../assets/service-gallery/3.jpg'),
        require('../assets/service-gallery/4.jpg'),
        require('../assets/service-gallery/5.jpg'),
        require('../assets/service-gallery/6.jpg'),
        require('../assets/service-gallery/8.jpg'),
        require('../assets/service-gallery/9.png'),
        require('../assets/service-gallery/10.png'),
        require('../assets/service-gallery/11.jpg'),
        require('../assets/service-gallery/12.jpg'),
        require('../assets/service-gallery/13.jpg'),
        require('../assets/service-gallery/14.jpg'),
        require('../assets/service-gallery/15.jpg'),
        require('../assets/service-gallery/16.jpg'),
        require('../assets/service-gallery/17.jpg'),
        require('../assets/service-gallery/18.jpg'),
        require('../assets/service-gallery/19.jpg'),
        require('../assets/service-gallery/20.jpg'),
        require('../assets/service-gallery/21.jpeg'),
        require('../assets/service-gallery/22.jpeg'),
        require('../assets/service-gallery/23.jpeg'),
      ]}
    />
    {/* <GeneralBlurb blurb={'Kiffgo is easiest and cheapest way to book a man and van in the UK. Kiffgo.com and Kiffgo - Man & Van App is transforming the transportation industry by using technology and market price data. We use real-time data to let you book vans in advance, or on short notice - at the most competitive price possible. We offer Furniture Courier & Delivery Services, Home Removals, Flat or Apartment Moves, Office Moves, Professional Piano Moves, Store Pickup and Deliveries, and Same-Day Moves in London and Greater London that can arrive at your pickup location within 1 hour. We are building a platform similar to Uber for man-and-van delivery services. We take customer feedback seriously, and there are many things on the roadmap to deliver a seamless experience including live driver tracking, and driver ratings - so that you can find the best rated, and most affordable man and van drivers within your local area at the push of a button.'}/> */}
    <HowItWorksContainer />
    <WeDoItAllContainer />
    <HelpLifting />
  </Layout>
);

export default HomePage

