import { connect } from 'react-redux';
import HomepageHero from './HomepageHero';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { navigate } from 'gatsby';
import { editAddress, fetchSuggestions, selectAddress } from '../../bookingStep0/RoutePlanner/RoutePlannerActions';
import {
  saveUnfinishedPickupQuery,
  saveUnfinishedDropoffQuery,
  setJobNameFromHomePage
} from './HomepageHeroActions';
import { SERVICES } from '../../../library/services';
import Utils from '../../../library/utils';
import { clearAddress } from '../../../actions/AddressActions';
import { calculateCustomPrices } from '../../../actions/PriceActions';

class HomepageHeroContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickup: '',
      dropoff: '',
      selectedServiceIndex: 1, // Jukebox asked for this ...
      servicesDropdownOpen: false,

      // Show suggestions?
      showPickupSuggestions: false,
      showDropoffSuggestions: false,

      // Selected from dropdown:
      pickupSelected: false,
      dropoffSelected: false,

      loading: false
    };

    this.activeTimeout = null;
  }

  componentDidMount() {
    if (!Utils.isBlank(this.props.unfinishedAddressQueries.pickup)) {
      this.setState({
        pickup: this.props.unfinishedAddressQueries.pickup
      })
    }
    if (!Utils.isBlank(this.props.unfinishedAddressQueries.pickup)) {
      this.setState({
        dropoff: this.props.unfinishedAddressQueries.dropoff
      })
    }


    if (this.props.bookingAddresses.pickup.fullAddress) {
      this.setState({ pickup: this.props.bookingAddresses.pickup.fullAddress });
    }

    if (this.props.bookingAddresses.dropoff.fullAddress) {
      this.setState({ dropoff: this.props.bookingAddresses.dropoff.fullAddress });
    }

    this.pickupInput && this.pickupInput.focus();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unfinishedAddressQueries.pickup !== this.props.unfinishedAddressQueries.pickup) {
      this.setState({
        pickup: nextProps.unfinishedAddressQueries.pickup
      })
    }
    if (nextProps.unfinishedAddressQueries.dropoff !== this.props.unfinishedAddressQueries.dropoff) {
      this.setState({
        dropoff: nextProps.unfinishedAddressQueries.dropoff
      })
    }

    // Selected:
    if (nextProps.bookingAddresses.pickup.fullAddress !== this.props.bookingAddresses.pickup.fullAddress) {
      this.setState({
        pickup: nextProps.bookingAddresses.pickup.fullAddress,
        showPickupSuggestions: false
      })
    }
    if (nextProps.bookingAddresses.dropoff.fullAddress !== this.props.bookingAddresses.dropoff.fullAddress) {
      this.setState({
        dropoff: nextProps.bookingAddresses.dropoff.fullAddress,
        showDropoffSuggestions: false
      })
    }

    // Not in your area: edit manually
    if (!!nextProps.bookingAddresses.pickup.postcodeError && nextProps.bookingAddresses.pickup.postcodeError !== this.props.bookingAddresses.pickup.postcodeError) {
      this.props.editAddress('pickup')
    }
    if (!!nextProps.bookingAddresses.dropoff.postcodeError && nextProps.bookingAddresses.dropoff.postcodeError !== this.props.bookingAddresses.dropoff.postcodeError) {
      this.props.editAddress('dropoff')
    }
  }

  toggleServicesDropdown = () => {
    this.setState({
      servicesDropdownOpen: !this.state.servicesDropdownOpen
    })
  };

  selectService = (index) => {
    this.setState({
      servicesDropdownOpen: false,
      selectedServiceIndex: index
    })
  };

  handlePickupChange = (e) => {
    this.props.clearAddress('pickup');
    this.activeTimeout && clearTimeout(this.activeTimeout);
    this.setState({
      pickup: e.target.value,
      pickupSelected: false
    }, () => {
      this.activeTimeout = setTimeout(() => {
        if (this.state.pickup.length >= 3) {
          this.props.fetchSuggestions('pickup', this.state.pickup);
          this.setState({
            showPickupSuggestions: true
          })
        } else if (this.state.pickup.length === 0) {
          this.setState({
            showPickupSuggestions: false
          })
        }
      }, 500);
    });
  };

  handlePickupKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.dropoffInput.focus()
    }
  };

  handleDropoffChange = (e) => {
    this.props.clearAddress('dropoff');
    this.setState({
      dropoff: e.target.value,
      dropoffSelected: false
    }, () => {
      this.activeTimeout = setTimeout(() => {
        if (this.state.dropoff.length >= 3) {
          this.props.fetchSuggestions('dropoff', this.state.dropoff);
          this.setState({
            showDropoffSuggestions: true
          })
        } else if (this.state.pickup.length === 0) {
          this.setState({
            showDropoffSuggestions: false
          })
        }
      }, 500);
    });
  };

  handleDropoffKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.generateQuotes(this.state.pickupPostcode, this.state.dropoffPostcode)
    }
  };

  selectPickupAddress = (hit) => {
    this.setState({
      pickup: hit.suggestion,
      showPickupSuggestions: false,
      pickupSelected: true
    });

    this.props.selectAddress('pickup', hit)
  };

  selectDropoffAddress = (hit) => {
    this.setState({
      dropoff: hit.suggestion,
      showDropoffSuggestions: false,
      dropoffSelected: true
    });

    this.props.selectAddress('dropoff', hit)
  };

  checkPrices = () => {
    ReactGA.event({
      category: 'Web booking',
      action: 'Started on homepage',
      label: 'Check prices button clicked'
    });
    ReactGA.event({
      category: 'Browsing',
      action: 'Clicked Check prices on homepage',
      label: 'StartBookingContainer (hero)'
    });
    this.props.setJobName(SERVICES[this.state.selectedServiceIndex].jobName);
    !this.state.dropoffSelected && this.props.saveUnfinishedDropoffQuery(this.state.dropoff);
    !this.state.pickupSelected && this.props.saveUnfinishedPickupQuery(this.state.pickup);

    if (this.props.bookingAddresses.pickup.selectedFromSuggestion && this.props.bookingAddresses.dropoff.selectedFromSuggestion) {
      this.setState({ loading: true }, () => {
        this.props.calculateCustomPrices();
        setTimeout(() => {
          navigate('/book')
          // navigate('/choose-van-size')
        }, 1200)
      })
    } else {
      navigate('/book')
    }
  };

  render() {
    return (
      <HomepageHero
        {...this.props}

        pickup={this.state.pickup}
        dropoff={this.state.dropoff}

        handlePickupChange={this.handlePickupChange}
        handleDropoffChange={this.handleDropoffChange}
        handleDropoffKeyPress={this.handleDropoffKeyPress}
        handlePickupKeyPress={this.handlePickupKeyPress}

        selectedServiceIndex={this.state.selectedServiceIndex}
        servicesDropdownOpen={this.state.servicesDropdownOpen}
        toggleServicesDropdown={this.toggleServicesDropdown}
        selectService={this.selectService}

        setPickupRef={this.setPickupRef}
        setDropoffRef={this.setDropoffRef}

        selectPickupAddress={this.selectPickupAddress}
        selectDropoffAddress={this.selectDropoffAddress}

        showPickupSuggestions={this.state.showPickupSuggestions}
        showDropoffSuggestions={this.state.showDropoffSuggestions}

        checkPrices={this.checkPrices}

        loading={this.state.loading}
      />
    )
  }

  setPickupRef = (ref) => this.pickupInput = ref;
  setDropoffRef = (ref) => this.dropoffInput = ref;
}

function mapStateToProps(state) {
  return {
    bookingAddresses: state.bookingAddresses,
    getAQuote: state.getAQuote,
    unfinishedAddressQueries: state.unfinishedAddressQueries
  }
}

const mapDispatchToProps = {
  editAddress: editAddress,
  selectAddress: selectAddress,
  fetchSuggestions: fetchSuggestions,
  setJobName: setJobNameFromHomePage,
  saveUnfinishedPickupQuery: saveUnfinishedPickupQuery,
  saveUnfinishedDropoffQuery: saveUnfinishedDropoffQuery,
  clearAddress: clearAddress,
  calculateCustomPrices: calculateCustomPrices
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageHeroContainer);
