import React from 'react';
import styles from './WeDoItAll.styles';
import { css } from 'aphrodite';
import { SERVICES } from '../../../library/services';
import STYLES from '../../../styles/shared';
import { Link } from 'gatsby';
import SectionTitle from '../../../components/ui/SectionTitle/SectionTitle';

const WeDoItAll = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <SectionTitle
        title={'We do it all'}
        subTitle={'Furniture moves, flat moves, same-day deliveries, you name it'}
      />
      <div className={css(styles.servicesContainer)}>
        {SERVICES.slice(0, 6).map((service, index) => (
          <Link  style={{ textDecoration: 'none' }} to={service.url} key={index} className={css(styles.serviceBox)} onMouseEnter={() => props.hoverService(index)}
               onMouseLeave={props.leaveHoverService}>
            <div className={css(styles.serviceBoxContent)} style={{background: service.color}}>
              <h4 className={css(styles.serviceName)}>{service.name}</h4>
              <img
                className={css(styles.serviceImg, STYLES.noSelect, props.hoveredIndex === index && styles.serviceImgHovered)}
                src={service.img} alt={service.name}/>
            </div>
          </Link>
        ))}
      </div>

    </div>
  </div>
);

export default WeDoItAll;
